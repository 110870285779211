@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';

.layout {
  &__header {
    position: relative;
    z-index: 10;
  }

  &__router {
    overflow: hidden;
    //background-color: $color-background-new-dark;
    min-height: 65vh;
  }
}
