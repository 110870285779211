@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';

.gmt-promo {
  position: relative;
  z-index: 1000001;
  position: fixed;
  top: 0;
  background: $color-background-new-dark;

  &__video {
    width: 100vw;
    height: 100vh;
    object-fit: fill;
  }
}
