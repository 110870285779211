@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/functions';

$width-player: get-clamp-size($screen-desktop-1024, 265px, 124px);

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 1240px;
  width: 100%;

  margin: 0 auto;
  padding: 128px 18px 0 18px;

  .info {
    &Title,
    &Name {
      color: $color-white;
    }

    &Title {
      text-align: left;

      font-size: get-clamp-size(
        $screen-desktop-1024,
        $font-size-desc-h5,
        $font-size-mob-h5
      );
      font-weight: 700;
    }

    &Name {
      font-size: get-clamp-size($screen-desktop-1024, 100px, 36px);
      font-weight: 700;
    }
  }

  .players {
    $pt: 46px;

    position: relative;

    height: get-clamp-size(
      $screen-new-tablet,
      calc(346px + $pt),
      calc(162px + $pt)
    );
    width: 100%;

    padding-top: $pt;

    &Item {
      position: absolute;
      width: $width-player;

      left: calc($width-player / 2 * var(--item-number) * 0.8 + 22%);
      bottom: 0;

      &Image {
        object-fit: contain;
        width: 100%;
        height: 100%;

        :global(.spinner-container) {
          position: absolute;
          top: -80px;
        }
      }
    }
  }
}

@include for-size(screen-desktop-1024) {
  .header {
    .players {
      &Item {
        transform: translate(-20%, 0);
      }
    }
  }
}

@include for-size(screen-new-phone) {
  .header {
    .players {
      &Item {
        transform: translate(-38%, 0);
      }
    }
  }
}
