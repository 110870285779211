@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/functions';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .imagesContainer {
    position: relative;
    height: get-clamp-size($screen-desktop-1440, 486px, 238px);
    width: get-clamp-size(1240px, 692px, 342px, '%');

    .image {
      position: absolute;
      transition: all 0.3s ease;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }

      &:hover {
        transform: scale(1.2);
        z-index: 5;
      }
    }

    .firstImage {
      left: 0;
      bottom: 0;
      top: 0;
      z-index: 2;

      width: get-clamp-size($screen-desktop-1440, 233px, 121px);
    }

    .secondImage {
      left: 30%;
      top: 0;
      z-index: 1;

      width: get-clamp-size($screen-desktop-1440, 196px, 97px);
    }

    .thirdImage {
      bottom: 0;
      right: 0;
      top: 0;
      z-index: 2;

      width: get-clamp-size($screen-desktop-1440, 330px, 163px);
    }

    .fourthImage {
      left: 20%;
      bottom: 0;

      z-index: 4;

      width: get-clamp-size($screen-desktop-1440, 333px, 164px);
    }
  }

  .iconContainer {
    .icon {
      display: inline-block;
      width: get-clamp-size($screen-desktop-1440, 233px, 174px);
      height: get-clamp-size($screen-desktop-1440, 272px, 204px);

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: $color-primary;
        }
      }
    }
  }
}

@include for-size(screen-new-tablet-phone) {
  .header {
    flex-direction: column;
    gap: 35px;
  }
}
