@import 'src/assets/styles/functions';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';

.clientSection {
  border-top: 1px solid $color-blue-800;

  .container {
    max-width: 1720px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0 auto;

    padding: 24px get-clamp-size($screen-desktop-1920, 100px, 36px) 0
      get-clamp-size($screen-desktop-1920, 100px, 36px);
  }

  .rights,
  .listItemLink {
    font-size: $font-size-desc-caption;
  }

  .rights {
    &Label {
      color: $color-blue-100;
    }
  }

  .list {
    display: flex;
    align-items: center;
    gap: 36px;

    &Item {
      &Link {
        color: $color-white;
      }
    }
  }
}

@include for-size(screen-desktop-1024) {
  .clientSection {
    border-top: none;

    .container {
      width: 100%;

      flex-direction: column-reverse;
      justify-content: center;

      padding-left: 0;
      padding-right: 0;
    }

    .rights {
      padding-top: 12px;
    }

    .list {
      flex-wrap: wrap;
      justify-content: center;

      width: 100%;
      padding-bottom: 20px;

      border-bottom: 1px solid $color-blue-800;
    }
  }
}

@include for-size(screen-new-phone) {
  .clientSection {
    .container {
      padding-top: 0;
    }

    .list {
      flex-direction: column;
    }
  }
}
