@import 'src/assets/styles/functions';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';

.socialSection {
  position: relative;

  .container {
    display: flex;
    align-items: center;

    max-width: 1720px;
    margin: 0 auto;

    padding: 40px 0 24px get-clamp-size($screen-desktop-1920, 100px, 36px);
  }

  .info {
    width: get-clamp-size(1720px, 740px, 426px, '%');

    &Text {
      font-size: $font-size-desc-caption;
      color: $color-white;
    }
  }

  .list {
    position: absolute;
    right: 0;
    top: -2px;

    width: max(532px, 31.6vw);

    display: flex;
    align-items: center;
    gap: 24px;

    padding: 24px 40px;
    margin: 0 0 0 auto;

    background: $color-primary;
  }
}

@include for-size(screen-desktop-4K) {
  .socialSection {
    .list {
      width: 38vw;
    }
  }
}

@include for-size(screen-new-desktop) {
  .socialSection {
    .container {
      flex-direction: column;
      gap: 20px;

      padding-right: 0;
      padding-left: 0;
    }

    .info {
      width: 100%;
      padding: 0;
      text-align: center;
      padding: 0 16px;
    }

    .list {
      position: relative;
      width: 100%;
      justify-content: space-around;

      gap: 4px;

      padding-right: 16px;
      padding-left: 16px;
    }
  }
}
