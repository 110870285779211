@import 'src/assets/styles/_fonts';
@import 'src/assets/styles/_variables';
@import 'src/assets/styles/_mixin';

.gmt-menu {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  background: #1170f4;
  transform: translateX(-100%);
  transition: transform 0.4s ease-in-out;

  &_open {
    transform: translateX(0);
  }

  &__container {
    display: flex;
    height: 100%;

    padding-top: 56px;
    padding-bottom: 56px;

    overflow-y: auto;
    box-sizing: border-box;
  }

  &__list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;

    transition: transform 0.6s ease-in-out;
  }

  &__item {
    padding: 10px 0;
  }

  &__link {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    transition: color 0.2s linear;
    padding: 10px 0;
    display: block;
    position: relative;
    font-family: $font-toxigenesis;
    font-weight: 700;
    text-transform: uppercase;

    &.active {
      color: #1170f4;
      font-weight: bold;
      background-color: #fff;
    }
  }
}
