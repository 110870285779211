@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/functions';

$width-container: 1240px;

.description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  max-width: $width-container;
  width: 100%;

  padding: get-clamp-size($screen-desktop-1440, 128px, 68px) 36px
    get-clamp-size($screen-desktop-1440, 170px, 126px) 36px;
  margin: 0 auto;

  .leftSection {
    &Background {
      position: relative;

      width: get-clamp-size($screen-desktop-1024, 318px, 238px);
      height: get-clamp-size($screen-desktop-1024, 371px, 278px);

      background: $color-primary;
      -webkit-mask-image: url('../../../../assets/images/members/background-player.svg');
      mask-image: url('../../../../assets/images/members/background-player.svg');
      mask-size: contain;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
    }

    .imageBox {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -30px;

      display: inline-block;
      width: 100%;
      height: 100%;
      overflow: clip;

      .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .rightSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    flex-basis: get-clamp-size($screen-desktop-1440, 630px, 296px);

    .list {
      flex-basis: 100%;

      display: grid;
      grid-template-columns: repeat(auto-fill, 50%);
      grid-row-gap: 36px;

      &Item {
        &Label {
          display: block;

          font-size: $font-size-desc-caption;
          font-weight: 400;
          line-height: 146%;
          color: $color-primary;
        }

        &Value {
          font-size: get-clamp-size(
            $screen-desktop-1440,
            $font-size-desc-h4,
            $font-size-mob-h4
          );
          font-weight: 700;
          line-height: 150%;
          color: $color-white;
        }
      }
    }

    .listIcon {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      flex-basis: 100%;

      gap: 20px;
      padding-top: 40px;

      &Item {
        &Link {
          width: 32px;
          height: 32px;

          svg {
            width: 32px;
            height: 32px;
            object-fit: contain;
          }
        }
      }
    }
  }
}

@include for-size(screen-new-tablet) {
  .description {
    gap: get-clamp-size($screen-new-tablet, 86px, 24px);

    .rightSection {
      .list {
        grid-template-columns: 1fr;
      }
    }
  }
}

@include for-size(screen-new-tablet-phone) {
  .description {
    flex-direction: column;

    .rightSection {
      .list {
        text-align: center;
      }

      .listIcon {
        justify-content: center;
      }
    }
  }
}
