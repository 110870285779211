@import 'src/assets/styles/functions';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';

.events {
  .container {
    max-width: 1720px;
    width: 100%;

    margin: 0 auto;
    padding: 77px 16px 100px 16px;

    .labelBox {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .labelIcon {
      width: 55px;
      height: 64px;
    }

    .labelText {
      color: $color-white;

      font-size: get-clamp-size(
        $screen-desktop-1024,
        $font-size-desc-h2,
        $font-size-mob-h2
      );
      font-weight: 700;
      line-height: 146%;
    }

    .items {
      .item {
        margin-top: 64px;
      }
    }
  }
}
