@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/functions';

.body {
  padding-top: get-clamp-size($screen-new-desktop, 119px, 65px);

  .title {
    font-size: get-clamp-size(
      $screen-new-desktop,
      $font-size-desc-h2,
      $font-size-mob-h2
    );
    text-align: center;
    color: $color-white;
  }

  .itemContainer {
    padding-top: 65px;

    .item {
      color: $color-white;
      line-height: 146%;
      font-size: get-clamp-size(
        $screen-new-desktop,
        $font-size-desc-main,
        $font-size-mob-main
      );

      &:not(:first-child) {
        padding-top: 24px;
      }
    }
  }
}
