@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';

.scroll-button {
  width: 48px;
  height: 48px;
  border: none;
  outline: none;
  border-radius: 10px;
  position: fixed;
  cursor: pointer;
  bottom: 10px;
  right: 10px;
  z-index: 776;
  background-color: $color-background-new-light;

  display: flex;
  justify-content: center;
  align-items: center;

  @include for-size(screen-new-desktop) {
    width: 38px;
    height: 38px;
  }

  @include for-size(screen-new-tablet-phone) {
    width: 28px;
    height: 28px;
  }

  &:hover {
    opacity: 0.5;
  }

  &__icon {
    width: 12px;
    height: auto;
    transform: rotate(180deg);
    fill: #fff;

    @include for-size(screen-new-tablet-phone) {
      width: 8px;
    }
  }
}
