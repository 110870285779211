@import 'src/assets/styles/mixin';
@import 'src/assets/styles/variables';

.teamsHeader {
  background: $bg-header-primary;

  .title {
    @include lottie-animation-block;

    padding-right: 9%;
  }
}
