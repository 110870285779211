@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/functions';

.partnerItem {
  background: $color-primary;

  padding: 32px get-clamp-size($screen-desktop-1024, 32px, 16px);

  .logo {
    position: relative;
    z-index: 2;

    display: inline-block;

    max-width: 126px;
    width: 100%;

    &Image {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .description {
    padding-top: 32px;

    &Title {
      color: $color-white;
      font-weight: 700;
      font-size: get-clamp-size(
        $screen-desktop-1440,
        $font-size-desc-h4,
        $font-size-mob-h4
      );
    }

    &Text {
      padding-top: 24px;

      color: $color-white;
      font-size: get-clamp-size(
        $screen-desktop-1440,
        $font-size-desc-main,
        $font-size-mob-main
      );
    }
  }

  .socials {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;

    padding-top: 32px;

    &Icon {
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
}
