@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/functions';

.gmt-roster {
  &__tabs {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    margin: 0 auto;

    &-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      gap: 10px;

      max-width: 646px;
      width: 100%;

      padding: get-clamp-size($screen-desktop-1024, 170px, 100px) 16px 50px 16px;
    }

    &-item {
      cursor: pointer;

      &-text {
        position: relative;
        display: inline-block;

        overflow: clip;

        font-weight: 700;
        font-size: clamp(16px, 1.05vw, 24px);
        line-height: clamp(19px, 1.5vw, 24px);
        color: $color-text-new-secondary;

        transition: all 0.2s linear;
      }
    }

    &-item--inactive {
      .gmt-roster__tabs-item-text {
        &:after {
          content: '';

          position: absolute;
          bottom: -5px;
          right: 2px;

          height: 8px;
          width: 100%;

          background: $color-text-new-primary;

          transform: translate3d(-101%, 0, 0);
          transition: transform 0.6s;
          transition-timing-function: cubic-bezier(0.7, 0, 0.1, 1);
        }

        &:hover::after {
          transform: translate3d(105%, 0, 0);
        }

        &::before {
          content: attr(data-letters);

          position: absolute;
          z-index: 2;

          overflow: clip;

          width: 0;

          color: $color-text-new-primary;

          transition: width 0.6s 0s;
        }

        &:hover::before {
          width: 100%;
        }
      }
    }

    &-item--active {
      .gmt-roster__tabs-item-text {
        color: $color-text-new-primary;
      }
    }
  }
}
