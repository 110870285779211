@import './fonts';
@import './variables';
@import './functions';

*,
html,
body {
  font-family: $font-golos;
}

* {
  box-sizing: border-box;
}

.active-link {
  color: #faa214;
  fill: #faa214;

  span {
    color: #faa214;
    fill: #faa214;
  }

  p {
    color: #faa214;
    fill: #faa214;
  }
}

.page__title {
  margin-bottom: 30px;
  padding: 0;

  font: $font-rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 57px;

  letter-spacing: -0.01em;

  color: $color-text-primary;

  @media (max-width: 1200px) {
    font-size: 32px;
    line-height: 38px;

    letter-spacing: -0.01em;
  }
}

.page__category-selector {
  margin-bottom: 25px;
}

.responsive-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.responsive-item {
  min-width: 290px;
  width: 31.8%;
  margin: 0 2.2% 24px 0;
  word-wrap: break-word;

  @media (min-width: 1200px) {
    &:nth-child(3n) {
      margin: 0 0 24px 0;
    }
  }

  @media (max-width: 1200px) {
    width: 48.6%;
    margin: 0 2.6% 24px 0;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0 0 24px 0;
  }
}

.responsive-related-item {
  min-width: 290px;
  width: 48.3%;
  margin: 0 3.3% 24px 0;
  word-wrap: break-word;

  @media (min-width: 1279px) {
    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: 1279px) {
    width: 48.3%;
    margin: 0 3.3% 24px 0;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @media (max-width: 720px) {
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    &:nth-child(2n) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.pagination {
  margin-top: 37px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 15px;

  @media (max-width: 768px) {
    width: 300px;
  }
}

.pagination > li {
  user-select: none;
  outline: none;
  display: inline-block;
  padding-left: 0;
  padding: 0 5px;
  min-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: skew(-10deg);
  border-radius: 7px;
  background-color: #202438;
  color: #fff7e8;
  margin-right: 10px;
}

.pagination > li {
  list-style: none;
}

.pagination > li.break {
  display: none;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  outline: none;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #fff7e8;
  transform: skew(10deg);
}

.pagination > li.active {
  background-color: #eba110;
  min-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: skew(-10deg);
  border-radius: 7px;
}

.pagination > li.disabled {
  display: none;
}

.pagination > li.active > a {
  outline: none;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 135%;

  letter-spacing: -0.01em;
}

.pagination > li > a:hover {
  cursor: pointer;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  padding: 0px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  display: none !important;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  outline: none;
}

.pagination > li:first-child {
  display: none;
}

.pagination > li:last-child {
  margin-left: 20px;
  padding: 0 30px;
  background-color: #eba110;
  outline: none;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 135%;

  letter-spacing: -0.01em;

  color: #fff7e8;
  @media (max-width: 768px) {
    margin: 0;
    margin-top: 20px;
  }
}

// sections

.section-preview {
  border-bottom: 1px solid #191d2b;
  padding: 36px 0;

  &__content {
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      align-items: center;
    }

    @media (max-width: 1200px) {
      flex-direction: column;
    }

    &-calendar {
      display: block;

      @media (min-width: 768px) and (max-width: 1200px) {
        display: flex;
        justify-content: space-between;
      }
    }

    &-details {
      display: none;

      @media (min-width: 768px) and (max-width: 1200px) {
        display: block;
        width: calc(100% - 435px);
      }
    }
  }
}

.section-intermediate {
  padding: 46px 0 48px 0;

  border-bottom: 1px solid #191d2b;
}

.section-end {
  padding: 46px 0 48px 0;
}
