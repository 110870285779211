@import 'src/assets/styles/functions';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/fonts';

.newsItem {
  width: 100%;

  &Header {
    &Title {
      font-weight: 700;
      font-size: get-clamp-size(
        $screen-desktop-1024,
        $font-size-desc-h5,
        $font-size-mob-h5,
        '%'
      );
      line-height: 150%;
      color: $color-white;
    }
  }

  &ImageBox {
    width: 100%;
  }

  &Image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &Body {
    &Text {
      padding-top: 32px;

      font-family: $font-popins;
      font-weight: 500;
      font-size: get-clamp-size(
        $screen-desktop-1024,
        $font-size-desc-main,
        $font-size-mob-main
      ) !important;
      line-height: 146%;
      color: $color-white;

      transition: all 0.3s linear;
      word-wrap: break-word;

      span,
      p,
      h1 {
        display: inline;
        font-size: get-clamp-size(
          $screen-desktop-1024,
          $font-size-desc-main,
          $font-size-mob-main
        ) !important;

        &::after {
          content: ' \A\A';
          white-space: pre;
        }
      }
    }
  }

  &Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-top: 32px;

    &Date,
    &Button {
      font-weight: 400;
      font-size: get-clamp-size(
        $screen-desktop-1024,
        $font-size-desc-caption,
        $font-size-mob-caption
      );
    }

    &Date {
      color: $color-blue-100;
    }

    &Button {
      display: flex;
      align-items: center;
      justify-content: center;

      background: transparent;

      color: $color-primary;
      text-transform: uppercase;
    }
  }
}

// --------------- News type: card
.newsItem.newsItemCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: $color-black-2;
  padding: get-clamp-size($screen-desktop-1440, 60px, 32px)
    get-clamp-size($screen-desktop-1440, 80px, 16px);

  .newsItemHeader {
    &Title {
      font-size: get-clamp-size(
        $screen-desktop-1440,
        $font-size-desc-h5,
        $font-size-mob-h5
      );
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      transition: all 0.3s linear;
      max-height: 109px;
    }
  }

  .newsItemBody {
    margin: 0 0 auto 0;

    height: 195px;

    &Text {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 7;
      display: -webkit-box;
      overflow: hidden;
    }

    iframe {
      display: none;
    }
  }
}

// --------------- News type: article
.newsItem.newsItemArticle {
  .newsItemHeader {
    &Title {
      font-size: get-clamp-size(
        $screen-desktop-1024,
        $font-size-desc-h4,
        $font-size-mob-h4
      );
    }
  }

  .newsItemImageBox {
    padding-top: 64px;
  }

  .newsItemBody {
    padding-top: 64px;

    figure {
      padding: 0 !important;
      height: get-clamp-size($screen-desktop-1440, 800px, 190px) !important;
    }

    iframe {
      border: none !important;
      height: get-clamp-size($screen-desktop-1440, 800px, 190px) !important;
      width: 100% !important;
    }
  }
}
