@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/fonts';

.new-slider {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: $color-background-new-light;

  &__container {
    transition: left 0.8s ease-out;
    display: flex;
    position: relative;
    height: 100%;

    &-img {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;

      &:after {
        background-color: #000;
        content: '';
        opacity: 0.3;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
      }
    }
  }

  &__container > * {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    position: relative;
  }

  &__container > * span {
    position: absolute;
    left: 71px;
    right: 71px;
    bottom: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 54px;
    color: #fff;
    text-shadow: 0px 2.78218px 2.78218px rgba(0, 0, 0, 0.8);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @include for-size(screen-new-desktop) {
      font-size: 20px;
      line-height: 24px;
      left: 20px;
      bottom: 18px;
      right: 20px;
      max-height: 47px;
      min-height: 34px;
    }

    @include for-size(screen-new-tablet-phone) {
      font-size: 10px;
      line-height: 12px;
      left: 10px;
      bottom: 12px;
      max-height: 24px;
      min-height: 17px;
      right: 10px;
    }
  }

  &__container > * img {
    width: 100%;
    height: 100%;
  }

  &__dots {
    position: absolute;
    text-align: center;
    bottom: 173px;
    left: 71px;
    display: flex;
    justify-content: flex-start;
    margin-right: 18px;

    @include for-size(screen-new-desktop) {
      left: 20px;
      bottom: 80px;
    }

    @include for-size(screen-new-tablet-phone) {
      left: 10px;
      bottom: 44px;
    }

    div {
      margin-right: 18px;
      display: flex;

      @include for-size(screen-new-desktop) {
        margin-right: 7px;
        height: 4px;
      }

      @include for-size(screen-new-tablet-phone) {
        margin-right: 3px;
        height: 4px;
      }

      &.active {
        position: relative;
        width: 100%;
        height: 100%;
        margin-right: 0;

        &:after {
          background-color: $color-background-new-light;
          width: 100%;
          height: 100%;
          content: '';
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          position: absolute;
          animation: fromLeft 10s linear;

          @include for-size(screen-new-desktop) {
            height: 4px;
          }

          @include for-size(screen-new-tablet-phone) {
            height: 2px;
            margin-top: 1px;
          }
        }
      }

      img {
        background: none;

        @include for-size(screen-new-desktop) {
          width: 29px;
        }

        @include for-size(screen-new-tablet-phone) {
          width: 15px;
        }
      }
    }
  }

  &__count {
    position: absolute;
    text-align: center;
    bottom: 197px;
    left: 71px;
    display: flex;
    justify-content: flex-start;
    font-weight: bold;
    font-size: 60px;
    line-height: 72px;
    color: $color-text-new-secondary;
    font-family: $font-toxigenesis;

    @include for-size(screen-new-desktop) {
      bottom: 92px;
      left: 20px;
      font-size: 30.455px;
      line-height: 37px;
    }

    @include for-size(screen-new-tablet-phone) {
      font-size: 14px;
      line-height: 17px;
      left: 10px;
      bottom: 49px;
    }
  }
}

@keyframes fromLeft {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
