@import 'src/assets/styles/functions';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';

.footer {
  padding: 24px 0 24px 0;
}

@include for-size(screen-new-tablet) {
  .footer {
    padding-left: 0;
  }
}
