@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/fonts';
@import 'src/assets/styles/functions';

.gmt-header__navigation-link {
  font-size: get-clamp-size($screen-new-big-desktop, 20px, 12px);
  text-transform: uppercase;
  font-weight: 700;
  color: $color-text-new-secondary;
  font-family: $font-toxigenesis;
  outline: none;
  text-decoration: none;
  position: relative;
  display: block;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0.2px;

  &:focus {
    outline: none;
  }

  &::after {
    content: '';
    position: absolute;
    height: 8px;
    width: 100%;
    bottom: -4px;
    right: 0;
    background: #217fff;
    transform: translate3d(-101%, 0, 0);
    transition: transform 0.6s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  }

  &::before {
    content: attr(data-letters);
    position: absolute;
    z-index: 2;
    overflow: hidden;
    color: #217fff;
    width: 0%;
    transition: width 0.6s 0s;
  }

  &.active {
    color: #217fff;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }
}
