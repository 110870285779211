@import 'src/assets/styles/variables';
@import 'src/assets/styles/functions';

.sponsorsList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: get-clamp-size($screen-desktop-1024, 40px, 16px);

  padding: 20px get-clamp-size($screen-desktop-1024, 40px, 16px);
}
