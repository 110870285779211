@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/functions';

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: get-clamp-size($screen-desktop-1024, 36px, 24px);
    min-width: get-clamp-size($screen-desktop-1024, 36px, 24px);

    margin: auto 4px;
    padding: 0 12px;

    text-align: center;
    color: $color-blue-200;
    letter-spacing: 0.01071em;
    line-height: 1.43;
    font-size: get-clamp-size($screen-new-tablet, 20px, 14px);
    font-weight: 700;

    transition: all 0.3s ease;

    &.dots:hover {
      background-color: transparent;
      cursor: pointer;
    }

    &:hover {
      cursor: pointer;
    }

    &.selected {
      background-color: #1170f4;
      color: #ffffff;
    }

    .arrow-btn {
      display: flex;
      justify-content: center;
      align-items: center;

      background: transparent;

      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
        pointer-events: none;

        svg {
          path {
            fill: $color-text-secondary;
            opacity: 0.5;
          }
        }
      }
    }

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
