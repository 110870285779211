@import 'src/assets/styles/variables';
@import 'src/assets/styles/functions';

.teamCards {
  $horizontal-padding: get-clamp-size($screen-desktop-1024, 40px, 16px);

  padding: 60px $horizontal-padding 0 $horizontal-padding;

  .cardBox {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;

    &:not(:first-child) {
      margin-top: 128px;
    }
  }

  .loaderBox {
    padding: 120px 0;
  }
}
