@import 'src/assets/styles/functions';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/fonts';

.newsItem {
  .container {
    max-width: 1240px;
    width: 100%;

    margin: 0 auto;
    padding: get-clamp-size($screen-desktop-1024, 128px, 68px) 16px;
  }
}
