@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/fonts.scss';
@import 'src/assets/styles/functions';

.gmt-home__videos {
  padding: 0 0 get-clamp-size($screen-desktop-1440, 200px, 50px) 0;

  &-title {
    text-align: center;
    font-size: get-clamp-size(
      $screen-new-desktop,
      $font-size-desc-h2,
      $font-size-mob-h2
    );
    color: $color-text-new-secondary;
    text-transform: uppercase;
    font-weight: 700;
    font-family: $font-toxigenesis;

    padding: get-clamp-size($screen-desktop-1440, 77px, 18px) 0 50px 0;
  }

  &-container {
    display: flex;
    flex-wrap: wrap;

    max-width: 1920px;
    margin: 0 auto;

    @media screen and (min-width: 1921px) {
      max-width: 100%;
    }

    .custom-slider {
      width: 100%;

      ul.slick-dots {
        bottom: 0;
        position: relative;

        padding: get-clamp-size($screen-desktop-1440, 50px, 25px) 0;

        & > li {
          background: rgba(255, 255, 255, 0.5);
          width: get-clamp-size($screen-desktop-1440, 80px, 15px);
          height: get-clamp-size($screen-desktop-1440, 8px, 2px);

          &.slick-active {
            background-color: #fff;
          }

          & > button {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            -webkit-appearance: none;
            -moz-appearance: none;
            background: transparent;
            border: none;

            &:before {
              content: none;
            }
          }
        }
      }
    }
  }
}

.gmt-home__streams {
  &-title {
    text-align: center;
    font-size: get-clamp-size(
      $screen-new-desktop,
      $font-size-desc-h2,
      $font-size-mob-h2
    );
    color: $color-text-new-secondary;
    text-transform: uppercase;
    font-weight: 700;
    font-family: $font-toxigenesis;

    padding: get-clamp-size($screen-desktop-1440, 77px, 18px) 0 50px 0;
  }

  &-container {
    display: flex;
    flex-wrap: wrap;

    max-width: 1920px;
    width: 100%;
    margin: 0 auto;

    @media screen and (min-width: 1921px) {
      max-width: 100%;
    }

    .custom-slider {
      width: 100%;

      ul.slick-dots {
        bottom: 0;
        position: relative;

        padding: get-clamp-size($screen-desktop-1440, 50px, 25px) 0;

        & > li {
          background: rgba(255, 255, 255, 0.5);
          width: get-clamp-size($screen-desktop-1440, 80px, 15px);
          height: get-clamp-size($screen-desktop-1440, 8px, 2px);

          &.slick-active {
            background-color: #fff;
          }

          & > button {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            -webkit-appearance: none;
            -moz-appearance: none;
            background: transparent;
            border: none;

            &:before {
              content: none;
            }
          }
        }
      }

      .slick-slide {
        width: get-clamp-size($screen-desktop-1440, 718px, 327px) !important;
      }
    }
  }
}

.gmt-home__active-content {
  width: 100%;
  height: auto;
}
