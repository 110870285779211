@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/_fonts.scss';
@import 'src/assets/styles/functions';

.slick-list {
  .slick-track {
    height: 100%;
  }
}

.slick-slide {
  width: get-clamp-size($screen-new-desktop, 600px, 327px) !important;
  height: auto;
  padding: 0 10px 10px 10px;

  & > div {
    height: 100%;
  }
}

.gmt-home__videos-container-item {
  position: relative;

  display: block !important;
  width: 100% !important;
  height: 100%;

  cursor: pointer;
  transition: all 0.2s linear;

  &.active {
    position: relative;

    & > img {
      opacity: 0.1;
      transition: all 0.1s linear;
    }

    transition: all 0.3s linear;

    &:hover {
      &:before {
        transform: translate(-50%, -100%) rotate(90deg) scale(1.05);
        box-shadow: 0 0 10px 0 $color-primary;
      }
    }

    &::before {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -100%);

      display: flex;
      justify-content: center;
      align-items: center;

      background: url('../../../../assets/images/remove.svg') center no-repeat;
      background-size: get-clamp-size($screen-desktop-1024, 30px, 20px)
        get-clamp-size($screen-desktop-1024, 30px, 20px);
      border: 2px solid $color-primary;
      border-radius: 50%;

      cursor: pointer;

      padding: get-clamp-size($screen-desktop-1024, 10px, 5px);
      transition: all 0.3s linear;
      width: get-clamp-size($screen-desktop-1024, 40px, 30px);
      height: get-clamp-size($screen-desktop-1024, 40px, 30px);
    }

    &:hover {
      outline: none;
    }
  }

  &:hover {
    outline: 5px solid $color-text-new-primary;
  }

  &:focus {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    background: $color-background-new-light;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: get-clamp-size($screen-desktop-1440, 20px, 10px);
    box-sizing: border-box;

    @include for-size(screen-new-tablet-phone) {
      height: fit-content;
    }

    &-title {
      font-family: $font-popins;
      font-style: normal;
      font-weight: 700;
      font-size: get-clamp-size(
        $screen-desktop-1024,
        $font-size-desc-main,
        $font-size-mob-main
      );
      line-height: 146%;
      color: $color-white;

      @include ellipsis-text;
    }

    &-time {
      font-family: $font-popins;
      font-style: normal;
      font-weight: 500;
      font-size: get-clamp-size(
        $screen-desktop-1024,
        $font-size-desc-main,
        $font-size-mob-main
      );
      line-height: 146%;

      color: #cfe2fd;

      margin: 12px 0 0 0;
    }
  }
}

.gmt-home__streams-container-item {
  position: relative;

  display: block !important;
  width: 100% !important;
  height: 100%;

  cursor: pointer;
  transition: all 0.2s linear;

  &.active {
    position: relative;

    & > img {
      opacity: 0.1;
      transition: all 0.1s linear;
    }

    transition: all 0.3s linear;

    &:hover {
      &:before {
        transform: translate(-50%, -100%) rotate(90deg) scale(1.05);
        box-shadow: 0 0 10px 0 $color-primary;
      }
    }

    &::before {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -100%);

      display: flex;
      justify-content: center;
      align-items: center;

      width: 40px;
      height: 40px;

      cursor: pointer;
      transition: all 0.3s linear;
      border: 2px solid $color-primary;
      border-radius: 50%;

      background: url('../../../../assets/images/remove.svg') center no-repeat;
      background-size: 30px 30px;

      padding: 10px;

      @include for-size(screen-new-desktop) {
        background-size: 20px 20px;
        width: 30px;
        height: 30px;
      }

      @include for-size(screen-new-tablet-phone) {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      outline: none;
    }
  }

  &:hover {
    outline: 5px solid $color-text-new-primary;
  }

  &:focus {
    outline: none;
  }

  &-img {
    display: block;

    width: 100%;
    height: 100%;

    object-fit: fill;
    cursor: pointer;

    margin-right: 50px;
  }

  &__content {
    $height-child: get-clamp-size($screen-desktop-1440, 111px, 20px);

    position: absolute;
    bottom: get-clamp-size($screen-desktop-1024, 20px, 5px);

    display: flex;
    align-items: center;

    width: 100%;

    padding: 0 get-clamp-size($screen-desktop-1024, 30px, 10px);

    cursor: pointer;

    &-left {
      display: flex;
      justify-content: center;
      flex-direction: column;

      overflow: hidden;

      width: 55%;
      flex-basis: 55%;
      height: $height-child;

      background: $color-background-new-light;

      padding: 0 get-clamp-size($screen-desktop-1024, 30px, 15px);

      &__title {
        font-family: $font-toxigenesis;
        font-size: get-clamp-size($screen-desktop-1440, 42px, 10px);
        line-height: 146%;
        font-weight: 700;
        color: $color-text-new-secondary;

        @include ellipsis-text;
      }

      &__subtitle {
        font-family: $font-toxigenesis;
        font-size: get-clamp-size($screen-desktop-1440, 24px, 10px);
        line-height: 146%;
        color: $color-text-new-secondary;
        font-weight: 700;

        @include ellipsis-text;
      }
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: center;

      gap: get-clamp-size($screen-desktop-1024, 30px, 10px);

      width: 44%;
      flex-basis: 44%;
      height: $height-child;

      background: $color-background-new-dark;
      overflow: hidden;

      &-img {
        width: get-clamp-size($screen-new-desktop, 51px, 16px);
        height: get-clamp-size($screen-new-desktop, 34px, 10px);
      }

      &-count {
        font-family: $font-toxigenesis;
        font-size: get-clamp-size($screen-desktop-1920, 60px, 8px);
        line-height: 146%;
        font-weight: 700;
        color: $color-text-new-secondary;
        word-wrap: break-word;
        overflow: hidden;
      }
    }
  }
}
