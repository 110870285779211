@import 'src/assets/styles/fonts';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';

.gmt-rosterVideosItem {
  $parent: &;

  height: 100%;
  background-color: #101820;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  display: flex;
  flex-direction: column;

  &_active {
    position: relative;
    transition: all 0.3s linear;

    &::before {
      position: absolute;
      background: url('../../../../assets/images/remove.svg') center no-repeat;
      background-size: 30px 30px;
      border: 2px solid #1170f4;
      border-radius: 50%;
      padding: 10px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      transition: all 0.3s linear;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -100%);
      content: '';
      width: 40px;
      height: 40px;

      @include for-size(screen-new-desktop) {
        background-size: 20px 20px;
        width: 30px;
        height: 30px;
      }

      @include for-size(screen-new-tablet-phone) {
        width: 20px;
        height: 20px;
      }
    }

    &:hover {
      outline: none;
    }

    &:hover:before {
      transform: translate(-50%, -100%) rotate(90deg) scale(1.05);
      box-shadow: 0px 0px 10px 0px #1170f4;
    }

    & #{$parent}__header {
      opacity: 0.1;
      transition: all 0.1s linear;
    }
  }

  &:hover {
    transform: scale(1.1);
  }

  &:hover > &__footer &__footerImage {
    path {
      fill: #1170f4;
    }
  }

  &__headerImage {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 34px;
    justify-self: flex-end;

    @include for-size(screen-new-big-desktop) {
      padding: 14px;
    }
  }

  &__footerInfo {
    width: 70%;
  }

  &__footerInfoTitle {
    margin-bottom: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    color: #ffffff;
    text-transform: uppercase;

    @include for-size(screen-new-big-desktop) {
      font-size: 16px;
      line-height: 19px;
    }

    @include for-size(screen-new-tablet) {
      font-size: 13px;
      line-height: 16px;
    }
  }

  &__footerInfoSubtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #cfe2fd;
    text-transform: uppercase;

    @include for-size(screen-new-big-desktop) {
      font-size: 16px;
      line-height: 19px;
    }

    @include for-size(screen-new-tablet) {
      font-size: 13px;
      line-height: 16px;
    }
  }

  &__footerImage {
    width: 58px;
    height: 41px;

    @include for-size(screen-new-big-desktop) {
      width: 49px;
      height: 35px;
    }

    path {
      transition: all 0.3s linear;
    }
  }
}
