@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/fonts';

.gmt-header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;

  @media (max-width: 1249px) {
    height: 77px;
  }

  &__navigation {
    $navParentSelector: &;

    display: flex;
    align-items: center;
    background: none;
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    padding: 0 clamp(60px, 5%, 100px);

    @media (max-width: 1249px) {
      display: none;
    }

    &-logo {
      display: block;
      width: 48px;
      height: 62px;
      background: none;
      cursor: pointer;
    }

    &-item {
      position: relative;
      margin-left: 40px;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        & #{$navParentSelector}-link {
          &::before {
            width: 100%;
          }

          &::after {
            transform: translate3d(101%, 0, 0);
          }
        }

        & #{$navParentSelector}-submenu {
          opacity: 1;
          visibility: visible;

          &-inner::before {
            transform: scale(1);
          }
        }
      }
    }

    &-submenu {
      position: absolute;
      top: 100%;
      width: max-content;
      visibility: hidden;
      opacity: 0;
      transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1);

      &-inner {
        background-color: $color-background-new-dark;
        padding: 6px 16px;
        max-width: 270px;
        display: flex;
        flex-direction: column;
        transform: translateY(20px);

        &::before {
          display: block;
          content: '';
          position: absolute;
          bottom: 100%;
          left: 0;
          background: white;
          width: 100%;
          height: 2px;
          transform: scaleX(0);
          transform-origin: left center;
          transition: transform 0.6s cubic-bezier(0.7, 0, 0.3, 1);
        }
      }

      &-link {
        display: block;
        font-size: 16px;
        line-height: 19.2px;
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 10px;
        margin-bottom: 10px;
        color: $color-text-new-secondary;
        font-family: $font-toxigenesis;
        transition: color 0.6s 0s;

        &:hover {
          color: #217fff;
        }

        @include for-size(screen-new-desktop) {
          font-size: 10px;
          line-height: 14px;
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
