@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/functions';

.teamCard {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 455px;

  background-color: $color-background-new-light;

  transition: all 0.3s ease-in-out;
  cursor: pointer;

  .content {
    position: relative;

    display: flex;
    justify-content: space-between;

    width: 100%;
    height: 100%;
    max-width: 1040px;

    padding: get-clamp-size($screen-desktop-1024, 108px, 60px)
      get-clamp-size($screen-desktop-1920, 30px, 4px) 0
      get-clamp-size($screen-desktop-1920, 30px, 4px);

    .icon {
      position: absolute;
      top: -95px;

      width: 110px;
      height: 128px;

      &Image {
        width: 100%;
        height: 100%;
      }
    }
  }

  &:has(:hover, :active) {
    background-color: $color-background-new-white;

    .icon {
      &Image {
        path {
          fill: $color-background-new-light;
        }
      }
    }

    [data-info] {
      color: $color-background-new-light;
    }
  }
}

@include for-size(screen-new-tablet) {
  .teamCard {
    .content {
      flex-direction: column-reverse;
    }
  }
}

@include for-size(screen-new-tablet-phone) {
  .teamCard {
    height: 367px;

    .content {
      .icon {
        top: -65px;

        height: 96px;
        width: 82px;
      }
    }
  }
}
