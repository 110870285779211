@font-face {
  font-family: 'Golos';
  font-weight: 400;
  font-style: normal;
  src: local('Golos'), url(../fonts/golos/Golos_Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Golos';
  font-weight: 500;
  font-style: normal;
  src: local('Golos'), url(../fonts/golos/Golos_Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Golos';
  font-weight: 600;
  font-style: normal;
  src: local('Golos'), url(../fonts/golos/Golos_DemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Golos';
  font-weight: 700;
  font-style: normal;
  src: local('Golos'), url(../fonts/golos/Golos_Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Golos';
  font-weight: 900;
  font-style: normal;
  src: local('Golos'), url(../fonts/golos/Golos_Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Rubik';
  font-weight: 700;
  font-style: normal;
  src: local('Rubik'), url(../fonts/rubik/Rubik-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Toxigenesis';
  font-weight: 700;
  font-style: normal;
  src: local('Toxigenesis'),
    url(../fonts/toxigenesis/bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Toxigenesis';
  font-weight: 400;
  font-style: normal;
  src: local('Toxigenesis'),
    url(../fonts/toxigenesis/regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  src: local('Poppins'),
    url(../fonts/poppins/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  src: local('Poppins'),
    url(../fonts/poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  font-style: normal;
  src: local('Poppins'),
    url(../fonts/poppins/Poppins-Bold.ttf) format('truetype');
}
