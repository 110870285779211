@import 'src/assets/styles/variables';
@import 'src/assets/styles/functions';

.partnerItems {
  .container {
    max-width: 1240px;
    width: 100%;

    margin: 0 auto;
    padding: get-clamp-size($screen-desktop-1440, 148px, 40px) 16px 64px 16px;
  }
}
