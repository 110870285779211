@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/functions';

.videos {
  position: relative;

  padding: 64px 0;

  background-color: $color-primary;

  &Hide {
    display: none;
  }

  .caretContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    max-width: 1240px;
    width: 100%;
    height: 128px;

    margin: 0 auto;

    .caret {
      position: absolute;
      top: -70%;
      left: calc(get-clamp-size($screen-desktop-1024, 318px, 238px) / 2 - 35px);

      display: inline-block;

      width: 110px;
      height: 128px;
    }
  }

  .title {
    text-align: center;
    font-size: get-clamp-size(
      $screen-new-desktop,
      $font-size-desc-h2,
      $font-size-mob-h2
    );
    line-height: 146%;
    color: $color-white;
  }

  .list {
    max-width: 1720px;
    width: 100%;

    padding: 0 get-clamp-size($screen-desktop-1440, 54px, 14px);
    margin: 0 auto;

    &Container {
      display: grid;
      grid-template-columns: repeat(
        auto-fill,
        get-clamp-size($screen-desktop-1440, 500px, 400px)
      );
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;

      margin: 0 auto;
      padding: get-clamp-size($screen-desktop-1440, 64px, 40px) 20px 40px 20px;
    }

    &Item {
      flex-basis: get-clamp-size($screen-desktop-1440, 559px, 400px);
    }

    &ContainerActive {
      margin: 0 auto;
    }

    &ContainerMedia {
      display: none;

      :global {
        .gmt-home__videos-title {
          display: none;
        }
      }
    }

    :global {
      .gmt-home__active-content {
        &.big {
          background-color: #101820;
        }
      }
    }
  }
}

@include for-size(screen-new-tablet-phone) {
  .videos {
    .caretContainer {
      .caret {
        left: 50%;
        transform: translate(-65%, 0);
      }
    }

    .title {
      padding-bottom: 20px;
    }

    .list {
      max-width: 100%;
      padding: 0;
      margin: 0;

      &Container {
        display: none;
      }

      &ContainerMedia {
        display: block;
        width: 100%;
      }
    }
  }
}
