@import 'src/assets/styles/variables';
@import 'src/assets/styles/functions';
@import 'src/assets/styles/mixin';

$transition-bg: background 0.5s ease-out;

.card {
  width: 100%;

  .body {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: clamp(190px, 15vw, 290px);

    background: linear-gradient(
        to left,
        $color-background-new-light calc(50% + 0.5px),
        #fff 50%
      )
      right;
    background-size: 200%;

    transition: $transition-bg;

    cursor: pointer;

    .icon {
      position: absolute;

      &Image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &Left {
        width: 43px;

        left: 31px;
      }

      &Right {
        width: 71px;

        right: 22px;
      }
    }

    .avatar {
      position: relative;
      top: -20px;

      display: inline-block;
      height: calc(100% + 40px);

      &Image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .footer {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 24px 0 29px 0;

    &:after {
      content: '';

      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: clamp(3px, 0.4vw, 7px);

      background: linear-gradient(
          to left,
          $color-background-new-light calc(50% + 0.5px),
          #fff 50%
        )
        right;
      background-size: 200%;
      transition: $transition-bg;
    }

    .nickname,
    .type {
      font-size: get-clamp-size(
        $screen-desktop-1440,
        $font-size-desc-main,
        $font-size-mob-main
      );
      font-weight: 400;
      color: $color-text-new-secondary;

      @include for-size(screen-desktop-2K) {
        font-size: $font-size-desc-h5;
      }
    }

    .type {
      color: $color-blue-200;
    }
  }

  &:hover {
    .body {
      background-position: left;
    }

    .icon {
      &Image {
        path {
          fill: $color-background-new-light;
        }
      }
    }

    .footer {
      &:after {
        background-position: left;
      }
    }
  }
}
