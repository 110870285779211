@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';

.gmt-roster {
  &__header {
    position: relative;

    background: -webkit-linear-gradient(top, #1170f4 50%, #101820 50%);

    &-title {
      display: flex;
      align-items: center;

      width: clamp(178px, 73vw, 1400px);

      margin: 0 auto;

      padding: clamp(50px, 11vw, 130px) 0 clamp(50px, 11vw, 130px) 0;
    }

    // the player image above the title
    &-player {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -40%);
      width: clamp(138px, 32vw, 614px);

      &-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@include for-size(screen-new-small-phone) {
  .gmt-roster {
    &__header {
      &-title {
        position: relative;
        left: -20px;
        margin: 0;
      }

      &-player {
        left: 65%;
      }
    }
  }
}
