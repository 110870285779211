@import 'src/assets/styles/mixin';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/functions';

.agencyHeader {
  background: $bg-header-primary;

  .title {
    @include lottie-animation-block;
    justify-content: center;
    text-align: center;

    h2 {
      color: $color-white;
      font-size: get-clamp-size($screen-desktop-1440, 100px, 36px);
    }
  }
}
