@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/functions';

.aboutUs {
  .container {
    max-width: 1240px;
    width: 100%;

    margin: 0 auto;
    padding: get-clamp-size($screen-desktop-1024, 128px, 68px) 30px
      get-clamp-size($screen-desktop-1024, 60px, 40px) 30px;
  }
}
