//fonts
$font-toxigenesis: 'Toxigenesis', sans-serif;
$font-golos: 'Golos', sans-serif;
$font-rubik: 'Rubik', sans-serif;
$font-popins: 'Popins', sans-serif;

// --------------- Font Sizes
$font-size-desc-h1: 64px;
$font-size-desc-h2: 44px;
$font-size-desc-h3: 30px;
$font-size-desc-h4: 24px;
$font-size-desc-h5: 20px;
$font-size-desc-main: 16px;
$font-size-desc-caption: 14px;

$font-size-mob-h1: 36px;
$font-size-mob-h2: 34px;
$font-size-mob-h3: 20px;
$font-size-mob-h4: 18px;
$font-size-mob-h5: 16px;
$font-size-mob-main: 15px;
$font-size-mob-caption: 13px;

// --------------- Colors
$color-primary: #1170f4;
$color-secondary: #217fff;
$color-white: #ffffff;
$color-black: #000000;
$color-black-2: #101820;
$color-blue-100: #cfe2fd;
$color-blue-200: #a0c6fb;
$color-blue-800: #072d62;

$bg-image-primary: url('/assets/images/new-background.png') no-repeat center top;

$bg-test-image-primary: url('/assets/images/test-bg.png') no-repeat center
  center fixed;

$bg-header-primary: -webkit-linear-gradient(
  top,
  $color-primary 50%,
  $color-black-2 50%
);

$color-background-dark: #0a0d14;
$color-background-midium: #191d2b;
$color-background-midium-light: #121520;
$color-background-light: #202438;

$color-background-new-dark: #101820;
$color-background-new-light: #1170f4;
$color-background-new-white: #fff;

$color-text-primary: #d8def0;
$color-text-secondary: #838daa;
$color-text-highlight: #eba110;
$color-text-highlight-light: #fbac11;

$color-text-new-primary: #217fff;
$color-text-new-secondary: #fff;
$color-text-new-tertiary: #101820;

$color-el-dark: #2c3552;
$color-el-midium: #8492bd;
$color-el-faint: #fff7e8;
$color-el-light: #fff;
$color-el-button-primary: #fbac11;
$color-el-button-secondary: #b34949;

$color-line-white: #fff;

$color-success: #7ab800;
$color-failure: #f45555;

// view
$border-radius-small: 5px;
$border-radius-midium: 10px;
$border-radius-big: 15px;

$transform-skew-el: -20deg;
$transform-skew-el-content: 20deg;

// screen resolution
$screen-phone: 320px;
$screen-tablet: 768px;
$screen-desktop: 1200px;

$test: #000;

// new screen resolution
$screen-desktop-4K: 3840px;
$screen-desktop-2K: 2048px;
$screen-desktop-1920: 1920px;
$screen-new-big-desktop: 1600px;
$screen-desktop-1440: 1440px;
$screen-new-desktop: 1200px;
$screen-desktop-1024: 1024px;
$screen-new-tablet: 834px;
$screen-new-phone: 430px;
$screen-new-tablet-phone: 650px;
$screen-new-small-phone: 374px;

$z-mobile-nav: 10000;
