@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/functions';

.rightSection {
  width: 58%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 12px;

  .currentImage {
    &Element {
      object-fit: cover;
      height: 369px;
      width: 100%;
    }
  }

  .listImages {
    display: flex;
    align-items: center;
    gap: 12px;

    .imageItem {
      position: relative;
      cursor: pointer;

      overflow: clip;

      &::before,
      &::after {
        content: '';
        position: absolute;
        bottom: 0;

        width: 0;
        height: 6px;

        background-color: $color-primary;

        transition: width 0.2s ease-in-out;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }

      &Active {
        &::after,
        &::before {
          width: 50%;
        }
      }

      &Element {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include for-size(screen-new-tablet) {
  .rightSection {
    width: 100%;
  }
}
