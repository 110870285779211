.spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1170f4;
}

.spinner {
  margin: 2px;
  display: inline-block;
  border: 3px solid #fff;
  border-radius: 50%;
  border-top-color: #1170f4;
  animation: 1s spin ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
