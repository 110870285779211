@import 'src/assets/styles/functions';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/variables';

.news {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 32px;

  max-width: 1240px;
  width: 100%;

  margin: 0 auto;
  padding: 64px 16px 64px 16px;

  .loaderContainer {
    padding: 60px;
    width: 100%;
  }

  @include for-size(screen-new-tablet) {
    grid-template-columns: 1fr;
  }
}
