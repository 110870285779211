@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/functions';

.gmt-home {
  &__welcome {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: calc(100vh - 100px);
    min-height: 500px;

    transition: height 0.3s ease-in;

    &-info {
      display: flex;
      justify-content: center;
      flex-direction: column;

      max-width: 973px;
      height: 100%;

      &-title,
      &-name {
        font-size: get-clamp-size($screen-desktop-1920, 120px, 26px);
      }

      &-title {
        text-transform: uppercase;
        font-weight: 700;
        color: $color-text-new-secondary;
        text-align: center;
        font-family: $font-toxigenesis;
      }

      &-name {
        width: 100%;

        background: $color-background-new-light;

        font-family: $font-toxigenesis;
        color: $color-text-new-tertiary;
        font-weight: 700;
        text-align: center;
      }
    }

    &-arrow {
      display: flex;
      justify-content: center;
      margin-top: 5rem;

      @include for-size(screen-new-tablet-phone) {
        margin-top: 2rem;
      }

      &-image {
        cursor: pointer;

        width: get-clamp-size($screen-new-desktop, 100px, 25px);
        height: get-clamp-size($screen-new-desktop, 170px, 43px);
      }
    }
  }

  &__banner {
    background-color: $color-background-new-dark;
    padding: get-clamp-size($screen-desktop-1920, 65px, 35px)
      get-clamp-size($screen-desktop-1920, 100px, 35px)
      get-clamp-size($screen-desktop-1920, 230px, 50px)
      get-clamp-size($screen-desktop-1920, 100px, 35px);

    @include for-size(screen-desktop-1440) {
      padding-bottom: get-clamp-size($screen-desktop-1440, 120px, 55px);
    }

    .new-slider {
      $hg: 850px;
      height: 100%;
      max-height: $hg;

      .new-slider__item {
        position: relative;

        max-height: $hg;

        span {
          bottom: 10px;
        }
      }

      .new-slider__container-img {
        img {
          width: 100%;
          max-height: $hg;
          object-fit: cover;
        }
      }
    }
  }

  &__media {
    @include bg-image-default;

    &-content {
      padding: 0 0 0 0;
    }
  }
}

@include for-size(screen-new-tablet) {
  .gmt-home {
    &__welcome {
      height: 60vh;
    }
  }
}
