@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';

$width-player: clamp(124px, 35%, 265px);

.players {
  position: relative;

  width: 73%;
  height: 100%;

  .item {
    position: absolute;
    bottom: -5px;

    width: $width-player;

    left: calc($width-player / 2 * var(--item-number) * 0.75);

    &image {
      object-fit: contain;
      width: 100%;
      height: 100%;

      :global(.spinner-container) {
        position: absolute;
        top: -125px;
      }
    }
  }
}

@include for-size(screen-new-tablet) {
  .players {
    margin: 0 auto;
  }
}

@include for-size(screen-new-phone) {
  .players {
    left: -30px;
  }
}

@include for-size(screen-new-small-phone) {
  .players {
    left: -50px;

    .item {
      width: 104px;
    }
  }
}
