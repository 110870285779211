@import 'src/assets/styles/variables';
@import 'src/assets/styles/functions';
@import 'src/assets/styles/mixin';

.cards {
  position: relative;

  @include bg-image-default(0 -200px);

  .container {
    max-width: 1240px;
    width: 100%;

    margin: 0 auto;

    padding: 40px 16px get-clamp-size($screen-desktop-1440, 164px, 100px) 16px;

    .item {
      margin-top: 64px;
    }

    .itemLoader {
      padding: 40px 0;
    }
  }
}
