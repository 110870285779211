.error-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #080b14;

  &__text {
    margin: 0 20px 20px 20px;
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 68px;
    color: #faa214;
  }

  &__button {
    width: 340px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1877f2;
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
  }
}
