.video-player {
  &__video {
    margin-bottom: 30px;
  }

  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 12;

    &-container {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
    }
  }
}
