@import './mixin';

//containers
.inner-container {
  padding: 0 15px;
  max-width: 1300px;
  margin: 0 auto;
  @include container-media();
}

.container-primary {
  padding: 60px 15px 24px 15px;
  @include container-media();
  @include container-properties();
}

.container-secondary {
  @include container-media();
  @include container-properties();
}
