@import './variables';

@mixin container-properties() {
  min-height: 100vh;
  max-width: 1300px;
  margin: 0 auto;
}

@mixin container-media() {
  @media (min-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (min-width: 1200px) {
    padding-left: 68px;
    padding-right: 68px;
  }
  @media (min-width: 1920px) {
    padding-left: 210px;
    padding-right: 210px;
  }
}

//mixin for transition
//mixin for hover effects

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin animation($name, $duration: 0s, $timing: ease, $type: infinite) {
  @if $name == 'none' {
    -o-animation: none;
    -moz-animation: none;
    -webkit-animation: none;
    animation: none;
  } @else {
    -o-animation: $name $duration $timing $type;
    -moz-animation: $name $duration $timing $type;
    -webkit-animation: $name $duration $timing $type;
    animation: $name $duration $timing $type;
  }
}

@mixin bg-image-default($position: top) {
  background: $color-black-2 $bg-image-primary;
  background-position: $position;
  @media screen and (min-width: 1920px) {
    background-size: 100%;
  }
}

@mixin for-size($size) {
  @if $size == screen-new-phone {
    @media screen and (max-width: $screen-new-phone) {
      @content;
    }
  } @else if $size == screen-new-tablet {
    @media screen and (max-width: $screen-new-tablet) {
      @content;
    }
  } @else if $size == screen-new-desktop {
    @media screen and (max-width: $screen-new-desktop) {
      @content;
    }
  } @else if $size == screen-new-tablet-phone {
    @media screen and (max-width: $screen-new-tablet-phone) {
      @content;
    }
  } @else if $size == screen-new-small-phone {
    @media screen and (max-width: $screen-new-small-phone) {
      @content;
    }
  } @else if $size == screen-desktop-1440 {
    @media screen and (max-width: $screen-desktop-1440) {
      @content;
    }
  } @else if $size == screen-desktop-1024 {
    @media screen and (max-width: $screen-desktop-1024) {
      @content;
    }
  } @else if $size == screen-new-big-desktop {
    @media screen and (max-width: $screen-new-big-desktop) {
      @content;
    }
  } @else if $size == screen-desktop-1920 {
    @media screen and (max-width: $screen-desktop-1920) {
      @content;
    }
  } @else if $size == screen-desktop-2K {
    @media screen and (min-width: $screen-desktop-2K) {
      @content;
    }
  } @else if $size == screen-desktop-4K {
    @media screen and (min-width: $screen-desktop-4K) {
      @content;
    }
  }
}

@mixin textAnimationPrimary($main-color: #fff, $secondColor: #1170f4) {
  overflow: clip;
  color: transparent;

  &:after {
    content: '';

    position: absolute;
    bottom: -2px;
    right: 0;

    height: 4px;
    width: 100%;

    background: $color-secondary;

    transform: translate3d(-101%, 0, 0);
    transition: transform 0.6s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
  }

  background: linear-gradient(
      to left,
      $main-color calc(50% + 0.5px),
      $secondColor 50%
    )
    right;
  background-size: 200%;
  -webkit-background-clip: text;

  transition: all 0.6s ease;

  &:hover {
    background-position: left;
  }

  &:hover::before {
    max-width: 40%;
  }

  &:hover:after {
    transform: translate3d(101%, 0, 0);
  }
}

@mixin ellipsis-text {
  max-width: 99.99%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

@mixin ellipsis-multi-text($clamp-line: 3, $max-width: 99.99%) {
  display: -webkit-box;
  max-width: $max-width;
  -webkit-line-clamp: $clamp-line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin lottie-animation-block() {
  display: flex;
  align-items: center;

  width: clamp(188px, 73vw, 1400px);

  max-height: 468px;

  margin: 0 auto;

  padding: clamp(50px, 11vw, 130px) 0 clamp(50px, 11vw, 130px) 0;

  @include for-size(screen-new-small-phone) {
    max-height: 288px;
  }
}
