@import 'src/assets/styles/variables';
@import 'src/assets/styles/functions';

.container {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;

  padding: 64px get-clamp-size($screen-new-tablet, 30px, 16px) 0
    get-clamp-size($screen-new-tablet, 30px, 16px);

  .cardWrapper:not(:first-child) {
    margin-top: 64px;
  }

  .paginationContainer {
    padding-top: 80px;
  }
}
