@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/functions';

.info {
  padding-bottom: 136px;

  width: 22%;

  span {
    display: block;

    color: $color-white;
  }

  .description {
    font-weight: 700;

    &Title {
      font-size: get-clamp-size(
        $screen-desktop-1920,
        $font-size-desc-h4,
        $font-size-mob-h4
      );
      line-height: 29px;
    }

    &Subtitle {
      font-size: get-clamp-size(
        $screen-desktop-1920,
        $font-size-desc-h2,
        $font-size-mob-h2
      );
      line-height: 53px;
    }
  }

  .link {
    margin-top: 80px;

    &Text {
      font-weight: 400;
      font-size: $font-size-desc-caption;
      line-height: 17px;
    }
  }
}

@include for-size(screen-new-tablet) {
  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    padding: 24px 0 35px 0;
  }
}

@include for-size(screen-new-tablet-phone) {
  .info {
    display: inline-block;

    padding-bottom: 20px;

    .description {
      display: block;
    }

    .link {
      text-align: right;
      margin-top: 0;
    }
  }
}
