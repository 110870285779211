.notifcation-modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.notifcation-modal__close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -45px;
  right: 0;
  background-color: #eba110;
  transform: skew(-10deg);
  padding: 7px 12px;
  border: none;
  border-radius: 7px;
  cursor: pointer;

  &-icon {
    fill: #fff;
    stroke: #fff;
    margin: 0;
    padding: 0;
    transform: skew(10deg);
  }
}

.notifcation-modal__content {
  position: relative;
  background: #121520;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 5;
  padding: 53px 90px;
  max-width: calc(640px - 90px);

  @media (max-width: 768px) {
    max-width: calc(350px - 60px);

    padding: 34px 30px 42px 30px;
  }

  &-text-container {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.01em;

    color: #d8def0;

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &-login-button {
    color: white;
    font-size: 20px;
    padding: 16px 50px;
    margin-top: 37px;
    background: #eba110;
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    border-radius: 7px;
    transform: skew(-10deg);
    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 22px;
    }

    &-text {
      transform: skew(10deg);
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 135%;
      letter-spacing: -0.01em;
      color: #fff7e8;
    }
  }
}
