@import 'src/assets/styles/functions';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';

.gmt-roster {
  &__players {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      get-clamp-size($screen-desktop-1920, 546px, 304px)
    );

    gap: 36px clamp(20px, 1.1%, 40px);
    justify-content: center;

    max-width: 1720px;
    width: 100%;

    padding: 36px 16px get-clamp-size($screen-desktop-1920, 290px, 100px) 16px;

    margin: 0 auto;

    @include for-size(screen-new-tablet) {
      grid-template-columns: repeat(auto-fill, minmax(338px, 44%));
    }
  }

  &__players-card-loader {
    height: 190px;
  }
}
