@import 'src/assets/styles/functions';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';

$width-logo: 141px;

.navSection {
  width: 100%;
  max-width: 1720px;
  margin: 0 auto;

  display: flex;
  align-items: center;

  padding-left: get-clamp-size($screen-desktop-1920, 100px, 36px);

  .logo {
    width: $width-logo;
    flex: 0 0 $width-logo;
    height: 61px;
  }

  .nav {
    display: flex;
    justify-content: center;

    flex: 0 0 calc(100% - $width-logo);

    &List {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: get-clamp-size($screen-desktop-1920, 40px, 15px);

      &Item {
        .link {
          font-size: get-clamp-size(
            $screen-desktop-1920,
            $font-size-desc-h5,
            $font-size-mob-h5
          );
          font-weight: 700;
          color: $color-white;

          &:hover,
          &Active {
            color: $color-primary;
          }
        }
      }
    }
  }
}

@include for-size(screen-new-desktop) {
  .navSection {
    flex-direction: column;
    align-items: center;
    padding-left: 0;

    gap: 40px;

    .logo {
      flex-basis: 61px;
    }
  }
}

@include for-size(screen-new-tablet) {
  .navSection {
    .nav {
      width: 100%;

      &List {
        width: 80%;

        display: grid;
        justify-items: center;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px get-clamp-size($screen-new-tablet-phone, 100px, 40px);
      }
    }
  }
}
