@import './fonts.scss';
@import './variables';
@import './mixin';

*,
html,
body {
  margin: 0;
  font-family: $font-toxigenesis;
}

html {
  background-color: #101820;
}

h1 {
  margin: 0;
}

li {
  list-style-type: none;
}

ul {
  margin-left: 0;
  padding-left: 0;
}

a {
  text-decoration: none;
  color: #000;
}
p {
  margin: 0;
}

body::-webkit-scrollbar {
  background: $color-background-new-white;
}

body::-webkit-scrollbar-thumb {
  background: $color-background-new-light;
}

body.tablet-prevent-scroll {
  @media (max-width: 1249px) {
    overflow: hidden;
  }
}
