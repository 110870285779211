@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/fonts';

.gmt-home__active-content {
  &.big {
    background: #2334463a;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    padding: 50px 0 25px 0;

    &__img {
      margin-top: 25px;
      border: 2px solid #1170f4;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      width: 40px;
      height: 40px;
      transition: all 0.3s linear;

      &:hover {
        transform: rotate(90deg) scale(1.05);
        box-shadow: 0px 0px 10px 0px #1170f4;
      }

      @include for-size(screen-new-desktop) {
        width: 30px;
        height: 30px;
      }

      @include for-size(screen-new-tablet-phone) {
        width: 20px;
        height: 20px;
      }

      &-icon {
        width: 30px;
        height: 30px;

        @include for-size(screen-new-desktop) {
          width: 25px;
          height: 25px;
        }

        @include for-size(screen-new-tablet-phone) {
          width: 20px;
          height: 20px;
        }
      }
    }

    .video-player__video {
      width: 55%;
      padding: 0;
      margin: 0;
      animation: scale-display 0.3s;

      @include for-size(screen-new-desktop) {
        width: calc(100% - 100px);
      }

      @include for-size(screen-new-tablet-phone) {
        width: calc(100% - 70px);
      }
    }
  }

  &.small {
    position: fixed;
    right: 0;
    width: 470px;
    bottom: 0;
    z-index: 10000;
    animation: scale-display 0.3s;

    @include for-size(screen-new-tablet-phone) {
      width: 100%;
      height: 405px;
      top: 0;
    }

    .video-player__video {
      padding: 0;
      margin: 0;
      background-color: #fff;
    }

    &__container {
      background: $color-background-new-white;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include for-size(screen-new-tablet-phone) {
        padding: 8px 15px;
      }

      &-left {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        margin: 0 15px;
        font-family: $font-toxigenesis;
        color: $color-text-new-primary;

        @include for-size(screen-new-tablet-phone) {
          margin: 0;
          -webkit-line-clamp: 1;
        }
      }

      &-right {
        display: flex;
        align-items: center;
        margin: 0 15px;

        @include for-size(screen-new-tablet-phone) {
          margin: 0;
        }

        &__maximize {
          margin-right: 15px;
          cursor: pointer;
          color: #101820;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: $font-toxigenesis;

          @include for-size(screen-new-tablet-phone) {
            display: none;
          }

          &-icon {
            width: 35px;
            height: 35px;
          }

          &:hover {
            color: $color-text-new-primary;

            svg {
              rect {
                fill: #1170f4;
              }
            }
          }

          &-icon {
            margin-right: 5px;
          }
        }

        &__close {
          cursor: pointer;
          display: inline-block;
          color: #101820;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: $font-toxigenesis;

          @include for-size(screen-new-tablet-phone) {
            justify-content: flex-end;
            padding: 0 30px 0 0;
          }

          svg {
            width: 30px;
            height: 30px;
          }

          &:hover {
            color: $color-text-new-primary;
          }

          &-icon {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

@keyframes scale-display {
  0% {
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
