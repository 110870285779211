@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/functions';

@mixin image-default {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.event {
  position: relative;

  width: 100%;

  padding: 92px 32px 32px 32px;

  background-color: #101820;

  .backgroundImageBox {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    &:before {
      content: '';

      position: absolute;
      top: 2px;
      left: 0;

      display: block;

      width: 100%;
      height: 100%;

      background: linear-gradient(to top, #101820, rgba(16, 24, 32, 0));
    }

    width: 100%;
    height: 174px;

    .backgroundImage {
      @include image-default;
      object-fit: cover;

      object-position: 0 25%;
    }
  }

  .header {
    position: relative;

    display: flex;
    align-content: center;
    justify-content: space-between;

    padding-bottom: 24px;

    &LogoBox {
      width: get-clamp-size($screen-new-tablet, 120px, 90px);
    }

    &Logo {
      @include image-default;
    }
  }

  .body {
    position: relative;

    padding-top: 29px;

    border-top: 1px solid $color-primary;

    display: flex;
    justify-content: space-between;

    &Left {
      flex-basis: calc(100% - 230px);
    }

    &Right {
      flex-basis: 230px;
      height: get-clamp-size($screen-desktop-1440, 346px, 306px);
    }

    &Title {
      text-transform: uppercase;
    }

    &Subtitle {
      display: block;
      color: $color-white;
      line-height: 146%;
      font-size: get-clamp-size(
        $screen-desktop-1024,
        $font-size-desc-caption,
        $font-size-mob-caption
      );
    }

    &Text {
      display: inline-block;

      color: $color-primary;
      font-weight: 700;
      line-height: 146%;
      font-size: get-clamp-size(
        $screen-desktop-1024,
        $font-size-desc-h3,
        $font-size-mob-h3
      );
    }

    &DateBox {
      padding-top: 40px;
    }

    &DescriptionBox {
      padding-top: 24px;
    }

    &Description {
      display: inline-block;

      @include ellipsis-multi-text($clamp-line: 3);
    }

    &Player {
      @include image-default;
    }
  }
}

@include for-size(screen-new-tablet-phone) {
  .event {
    .body {
      &Left {
        flex-basis: 100%;
      }

      &Right {
        display: none;
      }
    }
  }
}
