.global-loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 12123;

  &.background {
    background-color: #101920;
  }

  div {
    div {
      svg {
        min-height: 100vh !important;
        min-width: 100vw !important;
      }
    }
  }
}
