@import 'src/assets/styles/mixin';
@import 'src/assets/styles/variables';

.header {
  background: $bg-header-primary;

  &Title {
    @include lottie-animation-block;
  }
}
