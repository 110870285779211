@import 'src/assets/styles/functions';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';

.players {
  position: relative;

  .container {
    position: relative;
    z-index: 2;

    max-width: 1720px;
    width: 100%;

    margin: 0 auto;
    padding: 41px 16px 0 16px;

    .labelBox {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .labelIcon {
      width: 55px;
      height: 64px;
    }

    .labelText {
      color: $color-white;

      font-size: get-clamp-size(
        $screen-desktop-1024,
        $font-size-desc-h2,
        $font-size-mob-h2
      );
      font-weight: 700;
      line-height: 146%;
    }

    .items {
      display: grid;
      grid-template-columns: repeat(
        auto-fill,
        get-clamp-size($screen-desktop-1920, 546px, 304px)
      );
      justify-content: center;

      gap: 36px clamp(20px, 1.1%, 40px);

      padding-top: 89px;
    }
  }
}

@include for-size(screen-new-tablet) {
  .players {
    grid-template-columns: repeat(auto-fill, minmax(338px, 44%));
  }
}
