@import 'src/assets/styles/variables';
@import 'src/assets/styles/functions';
@import 'src/assets/styles/mixin';

.teamItem {
  .headerBox {
    background: $color-primary;
  }

  .anchors {
    display: flex;
    justify-content: space-between;
    align-items: center;

    max-width: 330px;
    width: 100%;

    margin: 0 auto;
    padding: 118px 15px 0 15px;

    &Item {
      font-size: get-clamp-size(
        $screen-desktop-1024,
        $font-size-desc-h5,
        $font-size-mob-h5
      );
      font-weight: 700;
      text-transform: uppercase;
      color: $color-white;

      cursor: pointer;
    }
  }
}
