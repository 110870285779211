@import 'src/assets/styles/variables';
@import 'src/assets/styles/functions';
@import 'src/assets/styles/mixin';

.agency {
  @include bg-image-default();
  background-position-y: 17%;

  padding-bottom: get-clamp-size($screen-desktop-1920, 164px, 100px);
}
