@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';
@import 'src/assets/styles/functions';

.leftSection {
  width: 42%;

  padding: 40px 24px 0 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .headerBox {
    padding-left: 40px;

    .title {
      color: $color-white;
      font-size: get-clamp-size($screen-desktop-1024, 44px, 32px);
      font-weight: 700;

      @include ellipsis-multi-text(2);
    }

    .description {
      color: $color-primary;
      font-size: get-clamp-size($screen-desktop-1024, 20px, 16px);
      font-weight: 700;

      padding-top: 12px;

      @include ellipsis-multi-text(12);
    }
  }

  .footerBox {
    display: flex;
    justify-content: center;

    gap: 8px;
    padding: 20px 40px;

    background-color: $color-primary;

    .locationWrapper {
      .locationTitle {
        color: $color-white;

        text-transform: uppercase;
        font-size: get-clamp-size($screen-desktop-1024, 20px, 16px);
      }

      .locationDescription {
        padding-top: 8px;

        color: $color-white;
        font-size: get-clamp-size($screen-desktop-1024, 14px, 13px);

        @include ellipsis-multi-text(3);
      }
    }
  }
}

@include for-size(screen-new-tablet) {
  .leftSection {
    width: 100%;

    flex-direction: initial;
    padding-bottom: 24px;

    .headerBox {
      width: 50%;
      padding-right: 40px;
    }

    .footerBox {
      height: 121px;

      align-self: end;

      padding: 24px;
    }
  }
}

@include for-size(screen-new-tablet-phone) {
  .leftSection {
    flex-direction: column;
    padding: 0;

    .headerBox {
      width: 100%;

      padding: 24px 16px;
    }
  }
}
