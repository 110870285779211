@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';

.gmt-buttons__load-more {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
  padding: clamp(20px, 2vw, 40px) 0 clamp(20px, 2vw, 40px) 0;

  cursor: pointer;

  background: transparent;

  &:disabled {
    cursor: not-allowed;
  }

  &-label {
    position: relative;

    display: inline-block;

    font-weight: 700;
    font-size: clamp($font-size-mob-h4, 1.6vw, 64px);
    line-height: 148%;
    text-transform: uppercase;
    color: $color-white;
  }

  &:not(&--disabled-animation) {
    .gmt-buttons__load-more-label {
      @include textAnimationPrimary();
    }
  }
}
