@import 'src/assets/styles/fonts';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';

.gmt-burger {
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;

  &:focus {
    outline: none;
  }

  &__item {
    height: 3px;
    position: relative;
    transform-origin: 1px 1px;
    background: #fff;
    transition: all 0.4s ease-in-out;

    &:nth-child(1) {
      transform: rotate(0);
    }

    &:nth-child(2) {
      opacity: 1;
    }

    &:nth-child(3) {
      transform: rotate(0);
    }

    &_open {
      background: #fff;

      &:nth-child(1) {
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }
}

.gmt-burger-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-mobile-nav;
  width: 100%;
  height: 77px;

  @media (max-width: 1249px) {
    display: block;
  }

  &__topLine {
    position: relative;
    z-index: 10;
    padding: 0 clamp(16px, 3%, 60px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__logo {
    width: 21px;
    height: 28px;
    cursor: pointer;

    &-image {
      width: 100%;
      height: 100%;
    }
  }
}
