@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixin';

.venue {
  width: 100%;
  background-color: $color-black-2;

  .mainSection {
    display: flex;
    justify-content: space-between;
  }
}

@include for-size(screen-new-tablet) {
  .venue {
    .mainSection {
      flex-direction: column-reverse;
    }
  }
}
